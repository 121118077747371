import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Deals from "./Pages/Deals";
import Login from "./Pages/Login";

export default function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/dashboard" element={<Deals/>} />
        </Routes>
    </Router>
  );
}