import React, { useEffect, useState, useRef } from "react";
import { useAlert } from 'react-alert'
import { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail } from "../firebase";

function Login({}) {
    const hostname = "https://heed.herokuapp.com";
    // const hostname = "http://localhost:5000";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selectStores, setSelectStores] = useState(false);
    const alert = useAlert();
    const [token, setToken] = useState("");
    const [retailerId, setRetailerId] = useState("");
    const [retailers, setRetailers] = useState([]);

    useEffect(() => {
        let token = localStorage.getItem("@token");
        let retailer = localStorage.getItem("@retailer");
        if (token && retailer) {
            window.location.href = "/dashboard";
        } else {
            localStorage.removeItem("@token");
            localStorage.removeItem("@retailer");
        }
    }, []);

    useEffect(() => {
        console.log(retailerId);
    }, [retailerId]);

    const login = () => {
        if (!email || !password) {
            alert.show('Please fill in all fields' , {
                timeout: 0,
                type: 'error'
            });
        }
        signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;

            if (!user.emailVerified) {
                // return alert.show('Please verify your email' , {
                //     timeout: 0,
                //     type: 'error'
                // });
            }

            let localToken = user.accessToken;
            setToken(localToken);


            // Pull retailers from API
            fetch(`${hostname}/api/buyersClub/account/${user.uid}` , { headers: {'Authorization': `Bearer ${localToken}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
            .then((response) => response.json())
            .then(async (response) => {
                if (response.success) {

                    if (response.data.length > 1) {
                        console.log(response.data);
                        setRetailerId(response.data[0].id);
                        setRetailers(response.data);
                        return setSelectStores(true);
                    } else if (response.data.length == 1) {
                        return selectLocation(localToken, response.data[0].id);
                    } else {
                        localStorage.setItem("@token", localToken);
                        localStorage.setItem("@user", email);
                        localStorage.setItem("@retailer", "admin");
                        window.location.href = "/dashboard";
                    }

                } else {
                    return alert.show(response.error , {
                        timeout: 0,
                        type: 'error'
                    });
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                return alert.show(errorMessage , {
                    timeout: 0,
                    type: 'error'
                });
            });            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return alert.show(errorMessage , {
                timeout: 0,
                type: 'error'
            });
            // ..
        });
    }


    const selectLocation = (localToken, localRetailer) => {
        
        localStorage.setItem("@token", localToken);
        localStorage.setItem("@user", email);
        localStorage.setItem("@retailer", localRetailer);
        window.location.href = "/dashboard";
    }


    return (
        <div className="App Login">


            {
                selectStores ? (
                    <div>
                        <h1 className="mb-6 text-3xl font-bold">Select Location</h1>
                        <select className="mt-2 p-2 py-2 rounded-sm border border-gray-300 w-100" value={retailerId} onChange={(e) => {setRetailerId(e.target.value)}}>
                            {retailers.map(retailer => {
                                return (
                                    <option value={retailer.id}>
                                        {retailer.retailer}
                                    </option>
                                )
                            })}

                            {/* {
                                process.env.NODE_ENV !== 'production' ? (
                                    <option value="testing">Demo Account</option>
                                ) : (
                                    <>
                                    <option value="61239787a61dca00a274ac5f">Neat Cannabis Komoka</option>
                                    <option value="5f46acf986efa201151435a3">Neat Cannabis Oxford</option>
                                    </>
                                )
                            } */}
                        </select>
                        <button style={{width:"100%", backgroundColor: "#64b591"}} className="mt-4 text-white hover:opacity-80 font-medium p-2.5 w-100 rounded-md" onClick={() => {selectLocation(token, retailerId)}}>Enter</button>
                        
                    </div>
                ) : (
                    <div>
                    <h1 className="mb-6 text-3xl font-bold">Login</h1>
                    <input className="mt-4 p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} value={email} type="email" onChange={((e) => {setEmail(e.target.value)})} placeholder="Email"></input>
                    <input className="mt-2 p-2 py-2 rounded-sm border border-gray-300" style={{width:"100%"}} value={password} type="password" onChange={((e) => {setPassword(e.target.value)})} placeholder="Password"></input>
                    <a href="#" onClick={() => {
                        if (!email) {
                            return alert.show("Please enter a valid email!" , {
                                timeout: 0,
                                type: 'error'
                            });
                        }

                        sendPasswordResetEmail(auth, email)
                        .then(function () {
                            alert.show(`Password reset email sent!`);
                        }).catch(function (e) {
                            alert.show(e.toString() , {
                                timeout: 0,
                                type: 'error'
                            });
                        })

                        
                    }} style={{alignSelf:"flex-start", marginTop:15, marginBottom:5, fontSize:14, color: "#5096FF"}}>Reset password</a>
                    <button style={{backgroundColor: "#64b591"}} className="mt-4 text-white hover:opacity-80 font-medium p-2.5 w-100 rounded-md" onClick={() => {login()}} >Login</button>
                </div>
                )
            }

        </div>
    );
}

export default Login;