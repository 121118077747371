import jsPDFInvoiceTemplate, { OutputType, jsPDF } from "jspdf-invoice-template-nodejs";


const downloadInvoice = (num, date, retailer, lineItems, totals) => {

    var props = {
        outputType: OutputType.Save,
        returnJsPDFDocObject: true,
        fileName: "HBC Invoice",
        orientationLandscape: false,
        compress: true,
        logo: {
            src: "https://i.imgur.com/meFy7aU.jpg",
            type: 'PNG',
            width: 26.66, //aspect ratio = width/height
            height: 26.66,
            margin: {
                top: 0, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        business: {
            name: "Heed",
            address: "123 Main St",
            phone: "(+355) 069 11 11 111",
            email: "email@example.com",
            email_1: "info@example.al",
            website: "www.example.al",
        },
        contact:retailer,
        invoice: {
            label: "Invoice #: ",
            num: num,
            invGenDate: `Invoice Date: ${date}`,
            headerBorder: false,
            tableBodyBorder: false,
            header: [
                {
                    title: "Data Deal",
                    
                },
                {
                    title: "$ Per Unit"
                },
                {
                    title: "Units"
                },
                {
                    title: "Earned"
                },
                {
                    title: "Heed Fee"
                },
                {
                    title: "Total"
                }
            ],
            table: lineItems,
            additionalRows: totals
        },
        pageEnable: true,
        pageLabel: "Page ",
    };

    jsPDFInvoiceTemplate(props);
}

export {
    downloadInvoice
}